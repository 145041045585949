import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Delete } from "@mui/icons-material";
import { useGetAPI } from "../../../../../shared/useAPI";
import { date, phoneNumber, currency } from "../../../shared/format";
import { useNavigate } from "react-router-dom";
import { useDeleteSavedApplication } from "../../../../../shared/NewApplication";
import { DeleteConfirmationModal } from "./DeleteConfirmationModal";
import errorStatusCodes from "../../../../../shared/NewApplication/errorStatusCodes";

const useStyles = makeStyles((theme) => ({
  name: {
    fontWeight: "bold",
  },
  email: {},
  phone: {},
  status: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  createdAt: {},
  updatedAt: {},
}));

function LastStatus({ code }) {
  if (code) {
    const { summary } = errorStatusCodes[code] || errorStatusCodes.default;
    return <>Last Status: {summary || errorStatusCodes.default.summary}</>
  } else {
    return <></>
  }
}

export default ({sortOrder}) => {
  const { data, loading, callAPI } = useGetAPI("/api/internal/v1/applications", { params: { sort_order: sortOrder } });
  useEffect(() => callAPI(), [sortOrder]);
  const classes = useStyles();
  const navigate = useNavigate();

  const [savedApplications, setSavedApplications] = useState(data);
  useEffect(() => { setSavedApplications(data) }, [data, sortOrder]);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedApp, setSelectedApp] = useState("");
  const { deleteSavedApplication } = useDeleteSavedApplication();
  const deleteApp = (app) => {
    setSelectedApp(app);
    setShowDeleteConfirmation(true);
  }
  const confirmDeleteSavedApplication = async (confirmed) => {
    setShowDeleteConfirmation(false);
    setSelectedApp(null);
    if (!confirmed) return;

    const { id } = selectedApp;
    deleteSavedApplication(id);
    const index = savedApplications.findIndex((app) => app.id === id);
    setSavedApplications([
      ...savedApplications.slice(0, index),
      ...savedApplications.slice(index + 1)
    ]);
  }

  return <>
    {loading &&
      <CircularProgress color="inherit" />}
    {!loading && savedApplications &&
      <>
        <Grid container item xs={12}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="saved applications table">
              <TableBody>
                {savedApplications.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Grid container direction="column">
                        <Grid item className={classes.name}>
                          {row.business_name}
                          {row.ein?.length > 0 ? <> ({row.ein})</> : ""}
                        </Grid>
                        {row.email && (
                          <Grid item className={classes.email}>
                            <a href={`mailto:${row.business_email}`}>{row.business_email}</a>
                          </Grid>
                        )}
                        {row.business_phone && (
                          <Grid item className={classes.phone}>{phoneNumber(row.business_phone)}</Grid>
                        )}
                        {(row.loan_amount || 0) > 0 && (
                          <Grid item className={classes.loan_amount}>{currency(row.loan_amount)}</Grid>
                        )}
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column">
                        <Grid item className={classes.createdAt}>Created: {date(row.created_at)}</Grid>
                        <Grid item className={classes.updatedAt}>Last Updated On: {date(row.updated_at)}</Grid>
                        <Grid item className={classes.lastStatus}>
                          <LastStatus code={row.error_code} />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Button
                        className="button-secondary"
                        variant="contained"
                        onClick={() => navigate(`/newApplication/${row.id}`)}
                      >
                        Continue Application
                      </Button>
                      <IconButton
                        className="button-danger button-round"
                        edge="end"
                        onClick={() => deleteApp(row)}
                        size="large">
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <DeleteConfirmationModal
          open={showDeleteConfirmation}
          onClose={confirmDeleteSavedApplication}
          applicationName={selectedApp?.business_name} />
      </>
    }
  </>;
};